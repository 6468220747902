import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import InputClear from "../../atoms/InputClear";
import "./login.css";
import { usersLogin, getAllUsersById } from "../../../api/services/user_services";
import { setProfile, clearState } from "../../../store/redux/actions/profile_actions";
import { decodeJwtToken } from "../../atoms/decode";
import { useDispatch, useSelector } from "react-redux";
import LoadingLogin from "../../atoms/LoadingLogin";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import ForgotPassword from "../../organisms/ForgotPassword/ForgotPassword";
import LanguageHandler from "../../organisms/LanguageHandler/LanguageHandler";
import { ApplicationContext } from "../../../context/ApplicationContext";
import ValidationAlert from "../../atoms/ValidationAlert";
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "../../../helpers/local-storage-manager";
import { useHistory } from "react-router-dom";
import { useGetExchanges } from "../../../hooks/Exchanges/useGetExchanges";
import { IRootState } from "../../../store/redux";
import { Typography } from "@mui/material";
interface Iuser {
  email: string;
  password: string;
}
export default function LoginNewAccount() {
  const [userInfo, setUserInfo] = useState<Iuser>({
    email: "",
    password: "",
  });
  const { setSuccessResetPassword, successResetPasswordMigated, successResetPassword, errorCheckSession, setErrorCheckSession } =
    useContext(ApplicationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [warning, setWarning] = useState<boolean>(false);
  const [forgotPasswordItem, setForgotPasswordItem] = useState<any>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { accounts } = profile;

  const [wrongAlertStatus, setWrongAlertStatus] = useState<boolean[]>([false, false]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [forgotToggle, setForgotToggle] = useState<boolean>(false);
  const { handleExchangesCount } = useGetExchanges("", false, true);

  const RenderError = () => {
    return (
      <div>
        {(wrongAlertStatus[0] || wrongAlertStatus[1]) && (
          <ValidationAlert
            message={wrongAlertStatus[0] ? errorMessage : wrongAlertStatus[1] ? `${t("ALERT-USER-INVALID-ROLE")}` : ""}
            variant="error"
          />
        )}
      </div>
    );
  };

  var exception = false;
  const loginAccount = async () => {
    if (!userInfo.email || !userInfo.password) return setWarning(true);
    setLoading(!loading);
    try {
      const response = await usersLogin(userInfo.email, userInfo.password);
      const data = response?.data;
      if (!data) throw new Error("You need to verify your email before login, please check your email inbox");
      if (!data?.result.migrated) {
        const userDec: any = decodeJwtToken(data?.result?.token, {
          complete: true,
        });
        if (userDec) {
          if (userDec.payload.roles_data.length >= 1) {
            // setRenderRoles(false)

            //CLEAR LOCAL STORAGE OF MIGRATED USER
            setInLocalStorage(LocalStorageKeys.USER_ID, "");
            setInLocalStorage(LocalStorageKeys.EMAIL, "");
            //CLEAR LOCAL STORAGE OF MIGRATED USER
            setWarning(false);
            //Set user token to get information
            dispatch(setProfile({ token: data.result?.token }));
            dispatch(
              setProfile({
                email: userDec.payload.email,
                full_name: userDec.payload.full_name,
                roles: userDec.payload.roles,
                roles_data: userDec.payload.roles_data,
                sub: userDec.payload.sub,
                username: userDec.payload.username,
                nameRolSelected: userDec.payload?.roles_data[0]?.name,
                profile_picture: userDec.payload.profile_picture,
              })
            );
            if (accounts && accounts?.length > 0) {
              if (!accounts?.find((account: any) => account.sub === userDec.payload.sub)) {
                dispatch(
                  setProfile({
                    accounts: [
                      ...accounts,
                      {
                        email: userDec.payload.email,
                        token: data.result?.token,
                        full_name: userDec.payload.full_name,
                        roles: userDec.payload.roles,
                        roles_data: userDec.payload.roles_data,
                        sub: userDec.payload.sub,
                        username: userDec.payload.username,
                        nameRolSelected: userDec.payload?.roles_data[0]?.name,
                        profile_picture: userDec.payload.profile_picture,
                      },
                    ],
                  })
                );
              }
            }
            const response = getAllUsersById(userDec.payload.sub);
            const datauser = (await response).data;
            //Languaje
            const activeLanguaje = `${datauser?.result?.users_settings?.lenguage_active}`.toLowerCase();
            let languageACT = "fr";
            if (activeLanguaje === "english") {
              languageACT = "en";
            }
            if (activeLanguaje === "french") {
              languageACT = "fr";
            }
            i18n.changeLanguage(languageACT);
            dispatch(
              setProfile({
                languaje: languageACT,
              })
            );
            setInLocalStorage(LocalStorageKeys.GLOBAL_SEARCH, "");
          } else {
            setWrongAlertStatus([false, true]);
          }
        }
        exception = false;
        history.push("/");
      } else {
        setInLocalStorage(LocalStorageKeys.USER_ID, data.result?.uuid);
        history.push("/verify-identity");
      }
    } catch (error: any) {
      console.log("error login");
      console.log(error);
      setWrongAlertStatus([true, false]);

      if (i18n.language === "fr") {
        setErrorMessage(
          `${error?.response?.data?.message}` === "Please verify your credentials"
            ? "Veuillez vérifier vos informations d'identification"
            : `${error?.response?.data?.message}` === "You need to verify your email before login, please check your email inbox"
            ? "Vous devez vérifier votre adresse e-mail avant de vous connecter, veuillez vérifier votre boîte de réception"
            : `${error?.response?.data?.message}` === "Incorrect username or password. Please try again."
            ? "Identifiant ou mot de passe incorrect. Veuillez réessayer."
            : `${error?.response?.data?.message}` === "The user is not authorized"
            ? "L'utilisateur n'est pas autorisé"
            : `${error?.response?.data?.message}` === "Cannot read property '0' of undefined"
            ? "Veuillez vérifier vos informations d'identification"
            : `${error?.response?.data?.message}`
        );
      } else {
        setErrorMessage(
          `${error?.response?.data?.message}` === "Cannot read property '0' of undefined"
            ? "Please verify your credentials"
            : `${error?.response?.data?.message}`
        );
      }
      if (!error?.response?.data?.message && error?.message) {
        if (i18n.language === "fr") {
          setErrorMessage("Vous devez vérifier votre adresse e-mail avant de vous connecter, veuillez vérifier votre boîte de réception");
        } else {
          setErrorMessage("You need to verify your email before login, please check your email inbox");
        }
      }
      exception = true;
      return;
    } finally {
      setLoading(false);
      setSuccessResetPassword(false);
      console.log("end");
      if (!exception) {
        window.location.reload();
      }
      setErrorCheckSession(false);
      setWarning(false);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    loginAccount();
  };
  const handleForgot = () => {
    if (!forgotToggle) {
      setForgotPasswordItem([
        {
          label: "show with transition",
        },
      ]);
    } else {
      setForgotPasswordItem([]);
    }
    setForgotToggle((prev) => !prev);
  };
  return (
    <div className="auth-signin" style={{ backgroundColor: "#F0F0F0", textAlign: "center", paddingTop: 75 }}>
      <div
        className="breadcrumb-login"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <h6>{"< "} Back to the dashboard</h6>
      </div>
      <div className="auth-signin-logo" style={{ top: 100 }}></div>
      <div className="log-box">
        <div className=" px-3">
          <form action="/" onSubmit={handleSubmit}>
            {successResetPassword && (
              <div className="my-3">
                <ValidationAlert message={`${t("RESET-PASSWORD-SUCCES-ALERT")}`} variant="success" />
              </div>
            )}
            {successResetPasswordMigated && (
              <div className="mt-3 mb-5">
                <ValidationAlert message={`${t("RESET-PASSWORD-SUCCES-ALERT-MIGRATED")}`} variant="success" />
              </div>
            )}

            {RenderError()}
            {warning && (
              <div>
                <ValidationAlert message={t("ALERT-GLOBAL-FILL-FIELDS")} variant="warning" />
              </div>
            )}
            <div className="py-2"></div>
            <InputClear
              type="text"
              name="distributor"
              placeholder={t("LOGIN-INPUT-USER")}
              style={
                !userInfo.email
                  ? { heigth: "250px" }
                  : {
                      heigth: "250px",
                      background: "var(--color-input-background-secondary)",
                    }
              }
              currentValue={userInfo.email}
              handleOnInputValue={(data: string) => {
                setUserInfo({ ...userInfo, email: data });
              }}
            />
            <div className="py-2"></div>
            <InputClear
              type="password"
              name="distributor"
              placeholder={t("LOGIN-INPUT-PASSWORD")}
              style={
                !userInfo.password
                  ? { heigth: "250px" }
                  : {
                      heigth: "250px",
                      background: "var(--color-input-background-secondary)",
                    }
              }
              currentValue={userInfo.password}
              handleOnInputValue={(data: string) => {
                setUserInfo({ ...userInfo, password: data });
              }}
            />
            <div className="py-3">
              <input className="button button-primary button-sm" type="submit" value={`${t("LOGIN-BUTTON")}`}></input>
              {/* <Button onClick={()=>loginAccount()} size="sm"><h6 style={{fontSize:'15px', margin:'2px 35px'}}><strong>Connexion</strong></h6></Button> */}
            </div>
          </form>
          <h6 className="text" onClick={() => handleForgot()}>
            {t("LOGIN-FORGOT-PASSWORD")}
          </h6>
        </div>
        <div className=" px-2">
          <List>
            <TransitionGroup>
              {forgotPasswordItem.map((item: any) => (
                <Collapse key={item}>
                  <ForgotPassword label={item.label} />
                </Collapse>
              ))}
            </TransitionGroup>
          </List>
        </div>

        <div className=" px-3">
          <h6
            style={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            v-2.0.5
          </h6>
        </div>
        <div className="px-3 pt-2">
          <LanguageHandler />
        </div>
      </div>

      <LoadingLogin open={loading} />
    </div>
  );
}
